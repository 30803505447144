import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Avatar from 'components/Avatar'
import { css } from '@emotion/core'
import LazyLoad from 'react-lazy-load'
import { Flex } from '@rebass/emotion'
import { IoIosInformationCircleOutline } from 'react-icons/io'

import { TRACKING_ACTIONS, EVENT_TYPES, NODE_TYPES } from 'utils/constants'
import { getLink } from 'utils/urlHelpers'

import StarRating from 'components/StarRating'
import { Star } from 'components/Iconography'
import {
	CardBox,
	InfoIconBox,
	NoRating,
	StarRatingBox,
	StrainLinkBox,
	StrainImageBox,
	StrainLink,
	StrainWrapper,
} from './styles'
// import StrainScoreIcon from 'components/StrainScoreIcon'
import { STRAIN_FINDER_IMAGE_SIZE } from 'utils/constants'
import { COLORS, FONT_SIZES } from 'utils/styleHelpers'
import track, { TrackingPropType } from 'react-tracking'
import { stampTime, eventDispatch } from 'utils/tracker'
import { SessionContext } from 'components/AuthContext'

function StrainListCard({ data, tracking }) {
	const {
		node: { strain },
	} = data
	const { sessionID, uid, userIp, userLocation, version } = useContext(SessionContext)
	const trackingData = {
		action: TRACKING_ACTIONS.STRAIN_FINDER_SELECT_ACTION,
		event: EVENT_TYPES.WR_STRAIN_FINDER_SELECT,
		nodeID: strain.id,
		nodeType: NODE_TYPES.STRAINS,
		nodeLabel: strain.name,
		sessionID,
		strainCategory: strain.category.toLowerCase(),
		uid,
		userIp,
		userLocation,
		version,
	}
	return (
		<CardBox>
			<StrainLink
				color={COLORS.GREEN}
				onClick={() => {
					tracking.trackEvent(trackingData)
				}}
				onKeyDown={() => {
					tracking.trackEvent(trackingData)
				}}
				role="link"
				tabIndex={-1}
				to={getLink(strain, NODE_TYPES.STRAINS)}
			>
				<StrainWrapper flexDirection="column">
					<Flex justifyContent="space-between" flexDirection="row">
						<StarRatingBox>
							{strain.averageRating === 0 ? (
								<NoRating>Not Yet Rated</NoRating>
							) : (
								<Flex flexDirection="row">
									<StarRating
										emptyStarColor={COLORS.MEDIUM_LIGHT_GRAY}
										name="reviewRating"
										renderStarIcon={Star({ fontSize: FONT_SIZES.H8 })}
										starColor={COLORS.GREEN}
										starCount={5}
										starRatingId={strain.id}
										value={strain.averageRating}
									/>
									<NoRating>
										{' '}
										of {strain.totalReviews} {strain.totalReviews > 1 ? 'reviews' : 'review'}
									</NoRating>
								</Flex>
							)}
						</StarRatingBox>
						<InfoIconBox>
							<IoIosInformationCircleOutline />
						</InfoIconBox>
					</Flex>

					<StrainImageBox alignSelf="center">
						<LazyLoad debounce={false} offsetVertical={500}>
							<Avatar size={[STRAIN_FINDER_IMAGE_SIZE, STRAIN_FINDER_IMAGE_SIZE]} src={strain.imageURL} />
						</LazyLoad>
					</StrainImageBox>
					<StrainLinkBox key={strain.id}>
						<Flex
							css={css`
								height: 60px;
								color: ${COLORS.WHITE};
							`}
							alignItems="center"
							justifyContent="center"
							flexDirection="column"
						>
							{strain.name}
						</Flex>
					</StrainLinkBox>
				</StrainWrapper>
			</StrainLink>
		</CardBox>
	)
}

StrainListCard.propTypes = {
	index: PropTypes.number,
	style: PropTypes.string,
	parent: PropTypes.object,
	tracking: TrackingPropType,
	data: PropTypes.shape({
		node: PropTypes.shape({
			strain: PropTypes.object,
		}),
	}),
}

export default track({ timestamp: stampTime() }, { dispatch: data => eventDispatch(data) })(StrainListCard)
