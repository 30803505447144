import styled from '@emotion/styled'
import { COLORS, SPACERS, FONT_SIZES, MQ } from 'utils/styleHelpers'
import { Flex, Box } from '@rebass/emotion'

export const ScoreNameContainer = styled(Flex)`
	background: ${COLORS.WHITE};
	padding-top: ${SPACERS.M};
	padding-bottom: ${SPACERS.M};
	padding-left: ${SPACERS.L};
	padding-right: ${SPACERS.L};
	margin-bottom: ${SPACERS.M};
	overflow: scroll;
	-webkit-overflow-scrolling: touch;
	transition: background 0.5s;
	.dark & {
		background: ${COLORS.MEDIUM_GRAY};
	}
`
export const ScoreNameBox = styled(Box)`
	text-align: center;
`

export const ScoreHeaderLabel = styled.label`
	color: ${({ color }) => color};
	font-size: ${FONT_SIZES.H3};
	${MQ.L} {
		cursor: pointer;
	}
`
export const ScoreFilterWrapper = styled(Flex)`
	height: 300px;
	padding-top: ${SPACERS.M};
	> div {
		padding: ${SPACERS.S};
	}
`
export const ScoreFilterBox = styled(Box)`
	padding-left: ${SPACERS.L};
	padding-right: ${SPACERS.L};
`

// export const ScoreNameButton = styled.button`
// 	border: 1px solid ${({ isChecked, color }) => (isChecked ? color : COLORS.MEDIUM_LIGHT_GRAY)};
// 	border-radius: 2px;
// 	background: ${({ isChecked, color }) => (isChecked ? color : COLORS.LIGHT_GRAY)};
// 	color: ${({ isChecked }) => (isChecked ? COLORS.LIGHT_GRAY : COLORS.BLACK)};
// 	font-size: ${FONT_SIZES.H7};
// 	padding-top: ${SPACERS.M};
// 	padding-bottom: ${SPACERS.M};
// 	width: 100%;
// 	.dark & {
// 		color: ${({ isChecked }) => (isChecked ? COLORS.WHITE : COLORS.LIGHT_GRAY)};
// 		background: ${({ isChecked, color }) => (isChecked ? color : COLORS.BLACK)};
// 		border: 1px solid ${({ isChecked, color }) => (isChecked ? color : COLORS.MEDIUM_LIGHT_GRAY)};
// 	}
// `
