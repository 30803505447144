import React, { useEffect, useRef, useState } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import track, { TrackingPropType } from 'react-tracking'
import { Flex, Box } from '@rebass/emotion'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import StrainList from 'components/StrainList'
import moment from 'moment'
import { useCurrentBreakpointName } from 'react-socks'
import { COLORS, FONT_SIZES, MQ, SPACERS } from 'utils/styleHelpers'
import { STRAIN_SCORES_TYPES } from 'utils/constants'
import { toSnakeCase } from 'utils/stringUtils'
import { stampTime, eventDispatch } from 'utils/tracker'
import { NoDisplayDesktop } from 'components/NoDisplay'
import GlobalLayout from 'components/GlobalLayout'
import { StrainFinder } from 'components/Iconography'
import FeatureSelector from 'components/FeatureSelector'
import StickyFooter from 'components/StickyFooter'

const viewResultBoxBase = css`
	font-size: ${FONT_SIZES.H4};
	sfont-weight: bold;
`

const PageHeader = styled.h1`
	color: ${COLORS.GREEN};
	font-weight: 600;
	font-size: ${FONT_SIZES.H4};
	${MQ.L} {
		font-size: ${FONT_SIZES.H1};
	}
	.dark & {
		color: ${COLORS.WHITE};
	}
`
const PageHeaderBox = styled(Box)`
	background: ${COLORS.WHITE};
	padding-left: ${SPACERS.XL};
	padding-right: ${SPACERS.XL};
	padding-top: ${SPACERS.L};
	padding-bottom: ${SPACERS.S};
	${MQ.L} {
		border-bottom: 0px;
	}
	text-align: center;
	transition: background 0.5s;
	.dark & {
		background: ${COLORS.MEDIUM_GRAY};
	}
`
const PageSubHeaderBox = styled(Box)`
	background: ${COLORS.WHITE};
	margin-bottom: ${SPACERS.M};
	padding-left: ${SPACERS.XL};
	padding-right: ${SPACERS.XL};
	padding-top: ${SPACERS.S};
	padding-bottom: ${SPACERS.L};
	text-align: center;
	transition: background 0.5s;
	.dark & {
		background: ${COLORS.MEDIUM_GRAY};
	}
`
const PageTitleBox = styled(Box)`
	background: ${COLORS.WHITE};
	display: none;
	padding-left: ${SPACERS.XL};
	padding-right: ${SPACERS.XL};
	padding-top: ${SPACERS.XL};
	padding-bottom: ${SPACERS.S};
	text-align: center;
	transition: background 0.5s;
	${MQ.M} {
		display: inline;
	}
	.dark & {
		background: ${COLORS.MEDIUM_GRAY};
	}
`

const PageSubHeader = styled.h3`
	font-weight: normal;
	font-size: ${FONT_SIZES.H5};
	${MQ.L} {
		font-size: ${FONT_SIZES.H4};
	}
`

const ResultsHeader = styled.h3`
	padding-top: ${SPACERS.S};
	padding-bottom: ${SPACERS.S};
	text-align: center;
	text-transform: uppercase;
	color: ${COLORS.MEDIUM_LIGHT_GRAY};
`
const ResultsHeaderWrapper = styled(Box)`
	background: ${COLORS.WHITE};
	box-shadow: 0px 3px 2px -2px rgba(0, 0, 0, 0.15);
	transition: box-shadow 0.5s, background 0.5s;
	width: 100%;
	.dark & {
		box-shadow: 0px 2px 3px -1px #0f0f0f;
		background: ${COLORS.BLACK};
	}
	${MQ.L} {
		display: none;
	}
`
const ResultToggleBoxLeft = styled.button`
	background: ${({ isActive }) => (isActive ? COLORS.LIGHT_GRAY : COLORS.DARK_GRAY)};
	border: 1px solid ${COLORS.MEDIUM_LIGHT_GRAY};
	border-radius: 3px;
	color: ${({ isActive }) => (isActive ? COLORS.BLACK : COLORS.WHITE)};
	font-weight: 800;
	font-size: ${FONT_SIZES.H6};
	margin-left: ${SPACERS.L};
	padding: ${SPACERS.M};
	padding-left: ${SPACERS.L};
	padding-right: ${SPACERS.L};
	transition: color 0.5s, background 0.5s;
`
const ResultToggleBoxRight = styled(Box)`
	${viewResultBoxBase}
	margin-right: ${SPACERS.L};	
`

const ResultToggleWrapper = styled(Flex)`
	background-color: ${COLORS.WHITE};
	box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
	width: 100vw;
	transition: background 0.5s, box-shadow 0.5s;
	.dark & {
		background-color: ${COLORS.BLACK};
		box-shadow: 1px 1px 10px #000;
	}
`

const StrainListBox = styled(Box)`
	display: ${({ isShowingResults, isMobile }) => (isShowingResults && !isMobile ? 'none' : 'flex')};
	flex: ${({ isShowingResults, isMobile }) => (isShowingResults && !isMobile ? '0' : '1')};
	opacity: ${({ isShowingResults, isMobile }) => (isShowingResults && !isMobile ? '0' : '1')};
	transition: visibility ${({ isShowingResults }) => (isShowingResults ? '0.1s' : '0s')},
		opacity ${({ isShowingResults }) => (isShowingResults ? '0.1s' : '0s')} linear;
	width: 100%;
`
const StrainListContainer = styled(Flex)`
	width: 100%;
`
const StrainRvBox = styled(Box)`
	height: 70vh;
	padding-top: ${SPACERS.M};
	margin-left: ${SPACERS.M};
	margin-right: ${SPACERS.M};
	${MQ.L} {
		height: ${({ desktopHeight }) => desktopHeight};
	}
	overflow-y: scroll;
`

const TemplateWrapper = styled(Flex)`
	padding-bottom: ${SPACERS.L};
	${MQ.M} {
		align-items: center;
		margin-top: ${SPACERS.XL};
		width: 100%;
	}
`

function StrainsPage({ data, location, tracking }) {
	const {
		allScoresJson: { edges: scoreNames },
		allStrainScoresJson: { edges: strainScores },
		allStrainsJson: { edges: strains },
	} = data

	const leftColumn = useRef(null)
	const rightColumn = useRef(null)
	const storageKey = makeNewSearchKey(Date.now())
	const [selectedFilters, setSelectedFilters] = useState([])
	const [isShowingResults, setIsShowingResults] = useState(false)
	const [filteredStrains, setFilteredStrains] = useState([])

	const rvList = useRef(null)
	const breakpoint = useCurrentBreakpointName()
	const isMobile = breakpoint === 'XS' || breakpoint === 'S'
	const effects = useRef(
		scoreNames.filter(node => {
			const {
				node: { type },
			} = node
			return toSnakeCase(type) === STRAIN_SCORES_TYPES.EFFECTS
		})
	)

	const negativeEffects = useRef(
		scoreNames.filter(node => {
			const {
				node: { type },
			} = node
			return toSnakeCase(type) === STRAIN_SCORES_TYPES.NEGATIVE_EFFECTS
		})
	)

	const medical = useRef(
		scoreNames.filter(node => {
			const {
				node: { type },
			} = node
			return toSnakeCase(type) === STRAIN_SCORES_TYPES.MEDICAL
		})
	)
	const scoresObject = useRef(
		scoreNames.reduce((acc, scoreName) => {
			const key = toSnakeCase(`${scoreName.node.name}_${scoreName.node.type}`)
			const value = strainScores.filter(strain => {
				const {
					node: { name, type },
				} = strain
				return scoreName.node.name === name && toSnakeCase(scoreName.node.type) === toSnakeCase(type)
			})
			return { ...acc, [key]: value }
		}, {})
	)

	const onSelectedFilter = ({ name, type }) => {
		const isInState = selectedFilters.filter(s => s.name === name).length > 0
		if (!isInState) {
			setSelectedFilters([...selectedFilters, { name, type: toSnakeCase(type), value: 50 }])
		} else {
			const filters = selectedFilters.filter(s => s.name !== name)
			setSelectedFilters(filters)
		}
	}
	useEffect(() => {
		try {
			const cachedFilters = getLocalStorageValue(storageKey)
			if (cachedFilters) {
				setSelectedFilters(cachedFilters)
			}
		} catch (e) {
			console.log(e.message)
		}
		return function cleanUp() {
			return Object.keys(localStorage).forEach(i => {
				if (i.includes('sfSearch:')) {
					const storageDate = moment(i.replace('sfSearch:', ''))
					const today = moment(new Date())
					// cache for 48 hours
					if (today.diff(storageDate, 'days') >= 2) {
						localStorage.removeItem(i)
					}
				}
			})
		}
	}, [storageKey])

	useEffect(() => {
		setLocalStorage(storageKey, selectedFilters)
		setFilteredStrains(processFilters({ selectedFilters, scoresObject: scoresObject.current, strains }))
	}, [selectedFilters, storageKey, strains])

	return (
		<>
			<GlobalLayout
				backgroundColor={COLORS.LIGHT_GRAY}
				location={location}
				pathname={location.pathname}
				isShowingFooter={false}
			>
				<TemplateWrapper flexDirection="column">
					<Box
						css={css`
							${MQ.L} {
								width: 100%;
							}
						`}
					>
						<Flex
							css={css`
								${MQ.M} {
									margin-left: 100px;
									margin-right: 100px;
								}
							`}
							flexDirection={['column', 'row']}
						>
							{(!isShowingResults || !isMobile) && (
								<Box
									css={css`
										${MQ.L} {
											flex-basis: 40%;
										}
									`}
									ref={leftColumn}
								>
									<Flex flexDirection="column">
										<PageTitleBox>
											<StrainFinder fontSize={FONT_SIZES.H1} />
										</PageTitleBox>
										<PageHeaderBox>
											<PageHeader>
												Discover marijuana strains that match your specific needs
											</PageHeader>
										</PageHeaderBox>
										<PageSubHeaderBox>
											<PageSubHeader>
												Select the options from each question that best describes what you are
												looking for.
											</PageSubHeader>
										</PageSubHeaderBox>
										<Box>
											<FeatureSelector
												features={effects.current}
												question={`What are you looking for in a high?`}
												onSelectedFilter={onSelectedFilter}
												selectedFilters={selectedFilters}
												type={STRAIN_SCORES_TYPES.EFFECTS}
											/>
										</Box>
										<Box>
											<FeatureSelector
												features={medical.current}
												question={`What types of medical issues are you concerned with?`}
												onSelectedFilter={onSelectedFilter}
												selectedFilters={selectedFilters}
												type={STRAIN_SCORES_TYPES.MEDICAL}
											/>
										</Box>
										<Box
											css={css`
												margin-bottom: ${SPACERS.XL};
											`}
										>
											<FeatureSelector
												features={negativeEffects.current}
												question={`What negative reactions do you want to avoid?`}
												onSelectedFilter={onSelectedFilter}
												selectedFilters={selectedFilters}
												type={STRAIN_SCORES_TYPES.NEGATIVE_EFFECTS}
											/>
										</Box>
									</Flex>
								</Box>
							)}
							{(isShowingResults || !isMobile) && (
								<Box
									css={css`
										${MQ.L} {
											flex-basis: 60%;
										}
									`}
									ref={rightColumn}
								>
									<Flex flexDirection="column">
										{filteredStrains.length > 0 ? (
											<StrainListBox alignSelf={['center', 'flex-start']} isMobile={isMobile}>
												<StrainListContainer flexDirection="column">
													<ResultsHeaderWrapper>
														<ResultsHeader>Strains You May Enjoy</ResultsHeader>
													</ResultsHeaderWrapper>
													<StrainRvBox
														desktopHeight={
															leftColumn.current.clientHeight
																? `${leftColumn.current.clientHeight}px`
																: '100vh'
														}
													>
														<StrainList
															ref={rvList}
															selectedFilters={selectedFilters}
															filteredStrains={filteredStrains}
														/>
													</StrainRvBox>
												</StrainListContainer>
											</StrainListBox>
										) : (
											<>
												{selectedFilters.length > 0 && (
													<Box
														css={css`
															padding: ${SPACERS.L};
															font-size: ${FONT_SIZES.H4};
														`}
													>
														We&apos;re sorry. No strains match with what you are looking
														for. Try selecting less filters.
													</Box>
												)}
												{selectedFilters.length === 0 && (
													<Box
														css={css`
															padding: ${SPACERS.L};
															font-size: ${FONT_SIZES.H4};
														`}
													>
														Please select some features of marijuana strains you are
														interested in.
													</Box>
												)}
											</>
										)}
									</Flex>
								</Box>
							)}
						</Flex>
					</Box>
				</TemplateWrapper>
				<NoDisplayDesktop>
					<StickyFooter>
						<ResultToggleWrapper justifyContent="space-between" flexDirection="row" alignItems="center">
							<ResultToggleBoxLeft
								isActive={isShowingResults}
								onClick={() => setIsShowingResults(!isShowingResults)}
							>
								{!isShowingResults ? `Search Strains` : `Refine Search`}
							</ResultToggleBoxLeft>
							<ResultToggleBoxRight>
								<StrainFinder width={50} height={50} />
							</ResultToggleBoxRight>
						</ResultToggleWrapper>
					</StickyFooter>
				</NoDisplayDesktop>
			</GlobalLayout>
		</>
	)
}

StrainsPage.propTypes = {
	data: PropTypes.object,
	location: PropTypes.object,
	tracking: TrackingPropType,
}
export default track({ timestamp: stampTime() }, { dispatch: data => eventDispatch(data) })(StrainsPage)

export const pageQuery = graphql`
	query allScores {
		allScoresJson(sort: { fields: [type, name] }) {
			edges {
				node {
					id
					name
					type
				}
			}
		}
		allStrainsJson {
			edges {
				node {
					strain {
						id
						name
						category
						slug
						imageURL
						averageRating
						totalReviews
					}
				}
			}
		}
		allStrainScoresJson {
			edges {
				node {
					score
					name
					type
					strain
				}
			}
		}
	}
`
function getLocalStorageValue(key) {
	const val = localStorage.getItem(key)
	if (!val) return null
	try {
		return JSON.parse(val)
	} catch (e) {
		return null
	}
}
function setLocalStorage(key, value) {
	localStorage.setItem(key, JSON.stringify(value))
}
function makeNewSearchKey(date) {
	return `sfSearch:${moment(date).format('YYYY-MM-DD')}`
}

function processFilters({ scoresObject, selectedFilters, strains }) {
	if (selectedFilters.length === 0) return selectedFilters

	// map to create an array of strain ids
	// first get scores
	// then get strains, strains must have all filters applicable.

	const filteredScores = selectedFilters.reduce((acc, filter) => {
		return [
			...acc,
			...scoresObject[toSnakeCase(`${filter.name}_${filter.type}`)].filter(node => {
				const {
					node: { score },
				} = node
				return filter.type === STRAIN_SCORES_TYPES.NEGATIVE_EFFECTS ? score <= 50 : score >= 50
			}),
		]
	}, [])
	const filteredStrains = selectedFilters.reduce((acc, filter) => {
		return [
			...strains.filter(strain => {
				const {
					node: {
						strain: { id },
					},
				} = strain
				return (
					filteredScores.filter(filteredScore => filteredScore.node.strain === id).length ===
					selectedFilters.length
				) // only return strains that have all the filters
			}),
		]
	}, [])
	return filteredStrains.sort((a, b) => {
		const {
			node: { strain: strainA },
		} = a
		const {
			node: { strain: strainB },
		} = b
		const A = strainA.name
		const B = strainB.name
		if (A < B) {
			return -1
		}
		if (A > B) {
			return 1
		}
		// names must be equal
		return 0
	})
}
