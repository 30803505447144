import React from 'react'
import PropTypes from 'prop-types'
import { SecondaryButton } from 'components/SecondaryButton'
import { Box } from '@rebass/emotion'

import { ScoreNameBox, ScoreNameContainer, ScoreHeaderLabel, ScoreFilterWrapper } from './styles'

import { toSnakeCase } from 'utils/stringUtils'
import { EFFECT_COLORS, FONT_SIZES } from 'utils/styleHelpers'

function FeatureSelector({ features, onSelectedFilter, selectedFilters = [], question, type }) {
	const effectColor = EFFECT_COLORS[toSnakeCase(type)]
	return (
		<ScoreNameContainer flexDirection="column">
			<ScoreNameBox>
				<ScoreHeaderLabel id={`features${type}`}>{question}</ScoreHeaderLabel>
			</ScoreNameBox>
			<Box>
				<ScoreFilterWrapper flexDirection="column" flexWrap="wrap" justifyContent="flex-start">
					{features.map(node => {
						const {
							node: { name, id },
						} = node

						return (
							<Box key={id}>
								<SecondaryButton
									id={id}
									onClick={() => onSelectedFilter({ name, type })}
									value={name}
									name={toSnakeCase(name).toLowerCase()}
									isChecked={selectedFilters.filter(a => a.name === name).length > 0}
									color={effectColor}
									fontSize={FONT_SIZES.H7}
								>
									{name}
								</SecondaryButton>
							</Box>
						)
					})}
				</ScoreFilterWrapper>
			</Box>
		</ScoreNameContainer>
	)
}

FeatureSelector.propTypes = {
	features: PropTypes.array,
	onSelectedFilter: PropTypes.func,
	selectedFilters: PropTypes.array,
	question: PropTypes.string,
	type: PropTypes.string
}

export default FeatureSelector
