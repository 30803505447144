import styled from '@emotion/styled'
import { Box, Flex } from '@rebass/emotion'
import { Link } from 'gatsby'
import { COLORS, FONT_SIZES, SPACERS, shadeColor2 } from 'utils/styleHelpers'

export const CardBox = styled(Box)`
	margin-left: ${SPACERS.M};
	margin-right: ${SPACERS.M};
	min-height: 230px;
`
export const InfoIconBox = styled(Box)`
	padding-right: 2px;
	color: ${COLORS.GREEN} !important;
`
export const NoRating = styled.div`
	font-size: ${FONT_SIZES.H8};
	color: ${COLORS.MEDIUM_LIGHT_GRAY};
`

export const StarRatingBox = styled(Box)`
	padding-left: ${SPACERS.S};
`

export const StrainBox = styled(Box)`
	padding-right: ${SPACERS.M};
	padding-left: ${SPACERS.M};
	padding-top: ${SPACERS.S};
	padding-bottom: ${SPACERS.S};
	width: 100%;
`
export const StrainLinkBox = styled(Box)`
	background: ${COLORS.GREEN};
	min-height: 60px;
	padding-right: ${SPACERS.M};
	padding-left: ${SPACERS.M};
	padding-top: ${SPACERS.S};
	padding-bottom: ${SPACERS.S};
	text-align: center;
	width: 100%;
`
export const StrainImageBox = styled(Box)`
	padding-right: ${SPACERS.M};
	padding-left: ${SPACERS.M};
	padding-top: ${SPACERS.S};
	padding-bottom: ${SPACERS.S};
	text-align: center;
	width: 100%;
`

export const StrainLink = styled(Link)`
	color: ${({ color = COLORS.WHITE }) => color};
	font-weight: bolder;
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
`
export const StrainWrapper = styled(Flex)`
	background: ${COLORS.WHITE};
	border-bottom: 1px solid ${shadeColor2(COLORS.MEDIUM_LIGHT_GRAY, 0.2)};
	text-align: center;
	transition: background 0.5s, border-bottom 0.5s;
	.dark & {
		background: ${shadeColor2(COLORS.BLACK, 0.1)};

		border-bottom: 1px solid ${shadeColor2(COLORS.BLACK, 0.2)};
	}
`
