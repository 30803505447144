import React from 'react'
import PropTypes from 'prop-types'
import { useCurrentBreakpointName } from 'react-socks'
import StrainListCard from 'components/StrainListCard'
import track, { TrackingPropType } from 'react-tracking'
import { Masonry } from 'masonic'
import { stampTime, eventDispatch } from 'utils/tracker'

function StrainList({ selectedFilters, rvList, filteredStrains }) {
	const breakpoint = useCurrentBreakpointName()
	const isMobile = breakpoint === 'XS' || breakpoint === 'S'
	const columnWidth = isMobile ? 160 : 220
	const spacer = isMobile ? 10 : 10

	return (
		<Masonry
			items={filteredStrains}
			columnGutter={spacer}
			columnWidth={columnWidth}
			overscanBy={10}
			render={StrainListCard}
		/>
	)
}
StrainList.propTypes = {
	tracking: TrackingPropType,
	selectedFilters: PropTypes.array,
	rvList: PropTypes.object,
	filteredStrains: PropTypes.array,
}

export default track({ timestamp: stampTime() }, { dispatch: data => eventDispatch(data) })(StrainList)
